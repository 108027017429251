import Vue from "vue";
import VueRouter from "vue-router";
import { wxLogin, getUserinfo } from "@/api/user";
import { mpAppId } from "../constants/mp";
import store from "../store/index";
import { parseLocationSearchString, isWeixin } from "@/util";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/payNew",
    name: "payNew",
    component: () => import("@/views/PayNew.vue"),
  },
  {
    path: "/xorpay",
    name: "xorpay",
    component: () => import("@/views/Xorpay.vue"),
  },
  {
    path: "/refund",
    name: "refund",
    component: () => import("@/views/Refund.vue"),
  },
  {
    path: "/refundNew",
    name: "refundNew",
    component: () => import("@/views/RefundNew.vue"),
  },
  {
    path: "/bind",
    name: "bind",
    component: () => import("@/views/Bind.vue"),
  },
  {
    path: "/qq",
    name: "qq",
    component: () => import("@/views/Qq.vue"),
  },
  {
    path: "/protocol/privacy",
    name: "privacy",
    component: () => import("@/views/protocol/Privacy.vue"),
  },
  {
    path: "/protocol/user",
    name: "user",
    component: () => import("@/views/protocol/User.vue"),
  },
];

const router = new VueRouter({
  routes,
});

const redirectMpAuth = (url) => {
  url = url || `https://${window.location.host}/#/`;
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${mpAppId}&redirect_uri=${encodeURIComponent(
    url
  )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
};

router.beforeEach(async (to, from, next) => {
  const res = await getUserinfo();
  if (!res || res.code != 0) {
    localStorage.removeItem("token-20250328");
  }
  if (res && res.data) {
    store.commit("updateUserinfo", res.data);
  }
  const token = localStorage.getItem("token-20250328");
  global.token = token;
  if (!global.token) {
    if (isWeixin()) {
      const queryObj = parseLocationSearchString(window.location.search);
      if (queryObj.code) {
        const res = await wxLogin(queryObj.code);
        if (res.code == 2002) {
          return redirectMpAuth(window.location.href);
        } else if (res.code == 0) {
          localStorage.setItem("token-20250328", res.data.token);
          window.location.reload();
        }
      } else {
        return redirectMpAuth(window.location.href);
      }
    }

    if (!isWeixin() && to.meta.requiredAuth) {
      next("/");
    }
  }

  next();
});

export default router;
