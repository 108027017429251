import { request } from "@/api/_base";

export const wxLogin = async (code) => {
  return await request({
    url: `/api/v1/mp/wxlogin`,
    params: {
      code,
    },
  });
};

export const getUserinfo = async () => {
  return await request({
    url: `/api/v1/user/info`,
    headers: {
      "X-Authorization": localStorage.getItem("token-20250328") || "",
    },
  });
};

export const bindEmail = async (email) => {
  return await request({
    method: "POST",
    url: `/api/v1/email/bind`,
    data: {
      email,
    },
    headers: {
      "X-Authorization": localStorage.getItem("token-20250328") || "",
    },
  });
};

const isProd = process.env.NODE_ENV === "production";
const APIHOST = isProd ? "https://qq.iizhi.cn" : "http://localhost:6003";
export const bindWxOpenid = async ({ wxCode, wxOpenid }) => {
  return await request({
    isPrefix: false,
    method: "POST",
    url: `${APIHOST}/api/v1/user/bindWxOpenid`,
    data: {
      wxCode,
      wxOpenid,
    },
  });
};
