import dayjs from "dayjs";
import { mapState } from "vuex";
import { mpAppId } from "@/constants/mp";
import { isWeixin } from "@/util";
import { getUserinfo } from "@/api/user";

Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

const install = (Vue) => {
  Vue.mixin({
    data() {
      return {};
    },
    computed: {
      ...mapState(["token", "isLogin", "userinfo"]),
    },
    filters: {
      formatTime(value) {
        return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    methods: {
      async updateUserinfo() {
        const res = await getUserinfo();
        if (res.code == 0) {
          this.$store.commit("updateLogin", true);
          this.$store.commit("updateUserinfo", res.data);
        } else if (res.code == 1001) {
          localStorage.setItem("token-20250328", "");
          this.$store.commit("updateToken", "");
          this.$store.commit("updateLogin", false);
        }
      },
      redirectMpAuth(url) {
        url = url || `https://${window.location.host}/#/`;
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${mpAppId}&redirect_uri=${encodeURIComponent(
          url
        )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
      },
      goLogin() {
        if (isWeixin()) {
          return this.redirectMpAuth(window.location.href);
        } else {
          if (this.$route.meta.requiredAuth) {
            this.$router.push("/");
          }
        }
      },
    },
  });
};

export default { install };
